module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"University of Michigan Library","short_name":"U-M Library","start_url":"/","background_color":"#00274C","theme_color":"#FFCB05","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f982c23145a9b92a0f61861d4dfbbe2a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"title","store":true,"attributes":{"boost":9}},{"name":"summary","store":true,"attributes":{"boost":3}},{"name":"keywords","store":true},{"name":"tag","store":true},{"name":"uniqname","store":true}],"resolvers":{"SitePage":{}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
